import Title from "../../components/Title";
import Paragraph from "../../components/Paragraph";
import Br from "../../components/Br";
import TextContainer from "../../components/TextContainer";

const About = () => (
    <TextContainer>
            <Title>
                Forensic Investigation of Website Domains, IP, & DNS
            </Title>

            <Paragraph>
                URL Forensics is a comprehensive toolset for investigating fraudulent or suspicious website domains and analysing DNS data. This toolbox facilitates the investigation of websites, IP addresses, hosting providers, email servers, domain creation dates, registrant details, and DMARC & SPF data.
                <Br/>
                <Br/>
                Fraudulent domains are frequently used in cybercrime, email fraud, and online scams. Analysing these domains is often the initial step in cybercrime investigations, with the domain’s creation date and email servers serving as key starting points.
                <Br/>
                <Br/>
                A domain investigation helps to identify the IP address from which an email originates, verifying its consistency with the IPs listed in the SPF record. URL Forensics was developed by DIGITPOL for these purposes.
                <Br/>
                <Br/>
                <b>About Us</b>
                <Br/>
                Founded in 2013, DIGITPOL is a licensed investigation agency specializing in cybercrime, digital forensics, fraud investigation, tracing, and intelligence. Licensed by the Netherlands Ministry of Justice under permit number 1557, DIGITPOL offers services in cybercrime investigation, cybersecurity, digital forensics, fraud investigation, incident response and criminal research.
                <Br/>
                <Br/>
                DIGITPOL collaborates closely with law enforcement agencies and national security services and is headquartered in the Netherlands.
            </Paragraph>
    </TextContainer>
)

export default About
