import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const S = {
  Page: styled.div`
    @media screen {
      height: 100%;
      background: #333638;
      max-width: 100vw;
      width: 100%;
      overflow-x: scroll;
    }
  `,
  ButtonWrapper: styled.div`
    display: flex;
    gap: 16px;
    bottom: 24px;
    right: 24px;
    position: fixed;
    z-index: 5;

    @media print {
      display: none;
    }
  `,
}

const ReportPage = ({ children, fromSearchPage }: { children: any; fromSearchPage?: boolean }) => {
  const history = useHistory()
  return (
    <S.Page>
      <S.ButtonWrapper>
        {fromSearchPage && (
          <>
            <Button onClick={() => history.push('/domain-investigation-search')} variant="secondary">
              &larr; Return to Search
            </Button>
            <Button onClick={() => history.push('/account')} variant="info">
              My Reports
            </Button>
          </>
        )}
        <Button onClick={window.print}>Print / Download</Button>
      </S.ButtonWrapper>

      {children}
    </S.Page>
  )
}

export default ReportPage
