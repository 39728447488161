import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import TextContainer from '../../components/TextContainer'

const Contact = () => (
    <TextContainer>
        <Title>
            Contact Us
        </Title>

        <Paragraph>
            URL Forensics is a tool developed and owned by DIGITPOL therefore technical support is offered directly by DIGITPOL.
            <a href='https://digitpol.com/contact-us/'> https://digitpol.com/contact-us/</a>
        </Paragraph>

        <Paragraph>
            <b>By Phone: </b><br/>
            Our phones can be reached on +31 558 448 040, available from 09:00 to 17:00, Monday to Friday.
            <br/>
            <br/>
            For out of office hours or <a href="/contact-form">contact via app</a>, Mobile: +31639220420
        </Paragraph>

        <Paragraph>
            <b>Via Email: </b><br/>
            You can use the contact form on our website to submit your questions or send us an email at info@digitpol.com
        </Paragraph>

        <Paragraph>
            <b>Address: </b><br/>
            <b>URLForensics</b><br />
            Boogschutterstraat 1 - 43,<br />
            7324 AE Apeldoorn,<br />
            The Netherlands.<br />
        </Paragraph>
    </TextContainer>
)

export default Contact
