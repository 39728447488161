import React from 'react'
import { Button, Form } from "react-bootstrap"
import useFormState from "../../hooks/useFormState"
import { useHistory } from "react-router-dom"
import { createUrl } from "../reports/GenerateReportForm"

const Search = () => {
  const [url, setUrl] = useFormState('')
  const history = useHistory()
  const link = createUrl(url, true)
  const onSubmit = (e) => {
    e.preventDefault()
    if (url) {
      history.push(link)
    }
  }
  return (
    <Form onSubmit={onSubmit} className="mx-auto py-5 px-4" style={{maxWidth: 600}}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Enter the domain without the http or www, example “google.com”</Form.Label>
        <Form.Control placeholder="digitpol.com" value={url} onChange={setUrl} />
      </Form.Group>

      <Button
        style={{ backgroundColor: '#0062cc' }}
        disabled={!url}
        onClick={() => history.push(link)}
      >
        <b>Generate Report</b>
      </Button>
    </Form>
  )
}

export default Search
