import styled from 'styled-components'
import config from '../styles'

const S = {
    Footer: styled.footer`
      font-size: ${config.fontSizes.small}px;
      color: ${config.colors.gray};
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      @media screen {
          bottom: ${config.pdfPadding}px;
          left: ${config.pdfPadding}px;
          right: ${config.pdfPadding}px;
      }
      @media print {
        page-break-after: always;
      }
    `,
    Primary: styled.span`
      color: ${config.colors.primary};
    `,
}

export default ({domain, date, uuid}) => (
    <S.Footer>
        Domain Forensic Investigation
        <br />
        <br />
        URL: {domain} Generated on {date} with File Hash Value: {uuid}
        <br />
        UrlForensics is a toolset developed by DIGITPOL
    </S.Footer>
)
