import styled from "styled-components";

export default styled.div`
  color: #3b3b3b;
  line-height: 1.6;
  font-size: 1.125rem;
  margin-top: 1em;
  margin-bottom: 1em;
  & > ol, & > ul {
    margin: 1rem;
    li {
      margin: 0.5rem 0;
    }
  }
`
