import styled from 'styled-components'
import styles from '../styles'
import Footer from './Footer'
import { ReactNode } from "react"

const S = {
    Logo: styled.img`
      margin: auto;
      display: block;
      height: 30px;
      page-break-before: always;
    `,
    PdfPage: styled.div`
      position: relative;
      overflow: hidden;
      height: 1468px;
      width: 100%;
      background-color: ${styles.colors.pdfBackground};

      // Add preview styling
      @media screen {
        margin-top: ${styles.pdfPadding}px;
        margin-bottom: ${styles.pdfPadding}px;
        padding: 46px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      }
    `,
    PageNumber: styled.div`
        position: absolute;
        top: 24px;
        right: 24px;
    `
}

export default ({ children, footer, pageNumber }: { children: any, footer: ReactNode, pageNumber: number }) => (
    <S.PdfPage>
        <S.PageNumber>{pageNumber}</S.PageNumber>
        <S.Logo src="https://storage.googleapis.com/digitpol-files/logo.png" alt="" />
        {children}

        {footer}
    </S.PdfPage>
)
