import React from 'react'
import TextContainer from '../../components/TextContainer'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'

const IdentifyFraudulent = () => {
  return (
    <TextContainer>
      <Title>
        How to identify a potential fraudulent domain in a Business Email Compromise (BEC) scam
      </Title>
      <Paragraph>
        Identifying a potentially fraudulent domain in a Business Email Compromise (BEC) scam is
        crucial for protecting your organization from financial loss and reputational damage. Here
        are several steps and indicators to help you recognize such domains:
      </Paragraph>
      <SubTitle>1. Examine the Domain Name</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Look for Misspellings:</b> Scammers often create domains that closely resemble
            legitimate ones but contain slight variations, such as missing letters, extra
            characters, or typos (e.g., example.com vs. examp1e.com).
          </li>
          <li>
            <b>Check for Unusual Extensions:</b> Be cautious of domains using less common extensions
            (e.g., .xyz, .top, .info) instead of more typical ones like .com, .org, or .net.
          </li>
          <li>
            <b>Analyze Length and Complexity:</b> Fraudulent domains may be unusually long, complex,
            or made up of random letters, making them difficult to remember.
          </li>
        </ul>
      </Paragraph>
      <SubTitle>2. WHOIS Lookup</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Check Registrant Information:</b> Use{' '}
            <a href="https://urlforensics.com/" target="_blank">
              WHOIS lookup tools
            </a>
            , URLForensics to obtain details about the domain owner. Look for:
            <ul>
              <li>
                <b>Inconsistent Information:</b> If the registrant’s name, email address,or
                organization doesn’t match known contacts or the legitimate business.
              </li>
              <li>
                <b>Privacy Protection Services:</b> While privacy protection is common, be cautious
                if the information is completely obscured or if the same protection service is used
                across multiple suspicious domains.
              </li>
            </ul>
          </li>
        </ul>
      </Paragraph>
      <SubTitle>3. Analyze Email Headers</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Review the Return Path:</b> Check the return-path address in email headers to see if
            it matches the expected domain.
          </li>
          <li>
            <b>Examine SPF/DKIM/DMARC Records:</b> Ensure that the domain's SPF (Sender Policy
            Framework) and DKIM (DomainKeys Identified Mail) records are properly configured. This
            helps verify that the email is sent from an authorized server. The SPF can be found in
            the URLForensic Report if its valid.{' '}
            <a href="https://urlforensics.com/" target="_blank">
              https://urlforensics.com/
            </a>
          </li>
          <li>
            <b>Look for Red Flags in the Header:</b> Unusual IP addresses or discrepancies in the
            sender's domain can indicate spoofing.
          </li>
        </ul>
      </Paragraph>
      <SubTitle>4. Cross-Reference with Known Threats</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Utilize Blacklists:</b> Check the domain against known blacklists or databases that
            track fraudulent domains associated with BEC scams and other cybercrimes.
          </li>
          <li>
            <b>Leverage Threat Intelligence Services:</b> Use cybersecurity tools that provide
            threat intelligence to identify and report on suspicious domains.
          </li>
        </ul>
      </Paragraph>
      <SubTitle>5. Investigate the Hosting Provider</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Check the Hosting Provider:</b> Research the domain’s hosting provider. Reputable
            businesses are typically hosted by well-known providers. Fraudulent domains may be
            hosted on servers with poor reputations.
          </li>
          <li>
            <b>Look for Short Lifespan:</b> Scammers often register domains for short periods (e.g.,
            a few months). A domain with a very recent registration date may be suspicious.
          </li>
        </ul>
      </Paragraph>
      <SubTitle>6. Search for Online Reputation</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Conduct a Web Search:</b> Perform a search on the domain name to see if it has been
            associated with scams or fraud. Look for reviews, complaints, or alerts.
          </li>
          <li>
            <b>Social Media Presence:</b> Check for the domain's presence on social media. A
            legitimate business usually has a consistent and professional online presence.
          </li>
        </ul>
      </Paragraph>
      <SubTitle>7. Monitor Communication Patterns</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Analyse Email Content:</b> Pay attention to the tone, urgency, and language used in
            emails. Phishing attempts often use high-pressure tactics or urgent language.
          </li>
          <li>
            <b>Verify Unusual Requests:</b> If an email requests sensitive information or financial
            transactions, confirm the request through a trusted communication channel.
          </li>
        </ul>
      </Paragraph>
      <SubTitle>8. Implement Multi-Factor Authentication (MFA)</SubTitle>
      <Paragraph>
        <ul>
          <li>
            <b>Enhance Security Measures:</b> Use MFA for sensitive accounts to add an extra layer
            of protection, making it more difficult for attackers to gain access.
          </li>
        </ul>
      </Paragraph>
    </TextContainer>
  )
}

export default IdentifyFraudulent
