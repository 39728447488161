import Home from './features/pages/Home'
import PrivacyStatement from './features/pages/PrivacyStatement'
import TC from './features/pages/TC'
import Contact from './features/pages/Contact'
import About from './features/pages/About'
import AccountView from './features/auth/AccountView'
import LogoutView from './features/auth/LogoutView'
import ReportView from './features/reports/ReportView'
import Organizations from './features/pages/Organizations'
import OrganizationDetail from './features/pages/OrganizationDetail'
import CreateAccount from './features/pages/CreateAccount'
import ContactForm from './features/pages/ContactForm'
import Investigation from './features/pages/Investigation'
import IdentifyFraudulent from './features/pages/IdentifyFraudulent'
import Search from "./features/pages/Search"

const routes = [
  {
    path: '/',
    title: 'Home',
    component: Home,
    exact: true,
    inFooter: true,
    inTopNav: false,
    showTopNav: false,
  },
  {
    path: '/privacy-policy/',
    title: 'Privacy',
    component: PrivacyStatement,
    exact: true,
    inFooter: true,
    inTopNav: false,
    showTopNav: true,
  },
  {
    path: '/terms-conditions/',
    title: 'Terms and Policy',
    component: TC,
    exact: true,
    inFooter: true,
    inTopNav: false,
    showTopNav: true,
  },
  {
    path: '/contact/',
    title: 'Contact',
    component: Contact,
    exact: true,
    inFooter: true,
    inTopNav: false,
    showTopNav: true,
  },
  {
    path: '/about-us/',
    title: 'About Us',
    component: About,
    exact: true,
    inFooter: true,
    inTopNav: false,
    showTopNav: true,
  },
  {
    path: '/account/',
    title: 'Account',
    component: AccountView,
    exact: false,
    inFooter: true,
    inTopNav: true,
    showTopNav: true,
    isAuth: true,
  },
  {
    path: '/organization/',
    title: 'Organization',
    component: Organizations,
    exact: false,
    inFooter: true,
    inTopNav: true,
    showTopNav: true,
    organization: true,
    isAuth: true,
  },
  {
    path: '/organizations/:id/',
    title: 'Organization',
    component: OrganizationDetail,
    exact: false,
    inFooter: false,
    inTopNav: false,
    showTopNav: true,
    organization: true,
    isAuth: true,
  },
  {
    path: '/reports/',
    title: 'Reports',
    component: ReportView,
    exact: false,
    inFooter: false,
    inTopNav: false,
    showTopNav: true,
  },
  {
    path: '/logout/',
    title: 'Logout',
    component: LogoutView,
    exact: false,
    inFooter: true,
    inTopNav: true,
    showTopNav: true,
  },
  {
    path: '/create-account',
    title: 'Create Account',
    component: CreateAccount,
    exact: true,
    inFooter: false,
    inTopNav: false,
    showTopNav: false,
  },
  {
    path: '/contact-form',
    title: 'Contact Form',
    component: ContactForm,
    exact: true,
    inFooter: false,
    inTopNav: false,
    showTopNav: false,
  },
  {
    path: '/domain-forensics-investigation',
    title: 'Domain Forensics Investigation',
    component: Investigation,
    exact: true,
    inFooter: false,
    inTopNav: false,
    showTopNav: false,
  },
  {
    path: '/identify-fraudulent-domain',
    title: 'Identify fraudulent domain',
    component: IdentifyFraudulent,
    exact: true,
    inFooter: false,
    inTopNav: false,
    showTopNav: false,
  },
  {
    path: '/domain-investigation-search',
    title: 'Domain Investigation Search',
    component: Search,
    exact: true,
    inFooter: false,
    inTopNav: false,
    showTopNav: false,
    isAuth: true,
  },
]

export default routes
