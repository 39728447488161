import React from 'react'
import styled from 'styled-components'
import { ErrorBoundary } from '@sentry/react'

import Get from '../../components/Get'
import { getDateTimeInWords } from '../../utils/datetime'
import PdfPreview from './components/PdfPreview'
import PdfPage from './components/PdfPage'
import Heading from './components/Heading'
import { Field } from './components/Table'
import ReportPage from './components/ReportPage'
import Loader from '../../components/Loader'
import TablePage from './components/TablePage'
import { Dmarc, DomainReportResponse, Whois } from './types'
import Footer from "./components/Footer"

const S = {
  Body: styled.p`
    font-size: 16px;
    white-space: pre-line;
  `,
  
}

interface ReportProps {
  data: DomainReportResponse
  fromSearchPage?: boolean
}

const getUrlFromSearch = (): string => {
  const search = new URLSearchParams(window.location.search)
  return search.get('url') || ''
}

const formatWhoisStatuses = (statuses: string = ''): string => {
  // Example input: ['ok https://icann.org/epp#ok']
  return statuses
    .replaceAll("'", '')
    .replaceAll('[', '')
    .split(']')
    .filter((v) => v.length > 1)
    .join(',')
}

const formatNameServers = (nameServers: string = ''): string => {
  // Example input: {'christina.neostrada.nl', 'sandra.neostrada.nl', 'lisa.neostrada.nl'}
  return nameServers.replaceAll("'", '').replaceAll('{', '').replaceAll('}', '')
}

const getWhoisFields = (whois: Whois): Field[] => [
  { label: 'Name', value: whois?.name },
  { label: 'Registrar', value: whois?.registrar },
  { label: 'Registrant Country', value: whois?.registrantCountry },
  { label: 'Creation Date', value: whois?.creationDate },
  { label: 'Expiration Date', value: whois?.expirationDate },
  { label: 'Last Updated', value: whois?.lastUpdated },
  { label: 'Statuses', value: formatWhoisStatuses(whois?.statuses) },
  { label: 'DNSSEC', value: whois?.dnssec },
  { label: 'Name Servers', value: formatNameServers(whois?.nameServers) },
  { label: 'Registrant', value: whois?.registrant },
]

const joinStrings = (warnings: any[] = []): string => warnings.map((v) => `${v}`).join(', ')

const getDMarcRecordFields = (data: Dmarc) =>
  data?.dmarc?.error
    ? [{ label: 'DMarc record', value: data?.dmarc?.error }]
    : [
        { label: 'DMarc record', value: data?.dmarc?.record || 'No record found' },
        { label: 'DMarc valid', value: data?.dmarc?.valid },
        { label: 'DMarc location', value: data?.dmarc?.location },
        { label: 'DMarc warnings', value: joinStrings(data?.dmarc?.warnings) },
      ]

const getDmarcFields = (data: Dmarc): Field[] => [
  { label: 'Domain', value: data?.domain },
  { label: 'Base Domain', value: data?.baseDomain },

  // NS
  { label: 'NS Hostnames', value: joinStrings(data?.ns?.hostnames) },
  { label: 'NS warnings', value: joinStrings(data?.ns?.warnings) },

  // MX
  ...(data?.mx?.hosts || []).flatMap((host): Field[] => [
    { label: `MX ${host?.hostname} preference`, value: host?.preference },
    { label: `MX ${host?.hostname} tls`, value: host?.tls },
    { label: `MX ${host?.hostname} starttls`, value: host?.starttls },
    { label: `MX ${host?.hostname} addresses`, value: joinStrings(host?.addresses) },
  ]),
  { label: 'MX warnings', value: joinStrings(data?.mx?.warnings) },

  // SPF
  { label: 'SPF record', value: data?.spf?.record },
  { label: 'SPF valid', value: data?.spf?.valid },
  { label: 'SPF dnsLookups', value: data?.spf?.dnsLookups },
  { label: 'SPF warnings', value: joinStrings(data?.spf?.warnings) },

  // DMarc
  ...getDMarcRecordFields(data),
]

const Report = ({ data, fromSearchPage }: ReportProps) => {
  const dmarcIsValid = data?.dmarc?.dmarc?.valid ? 'VALID' : 'INVALID'
  const spfRecordFound = data?.dmarc?.spf?.valid ? 'TRUE' : 'FALSE'
  const generatedAtInWords = getDateTimeInWords(data.created)
  const body = `
With reference the above, on ${generatedAtInWords}, Digitpol’s Domain Forensic Toolset
conducted a search on the domain name “${data.domain}” revealing the following details.
The domain “${data.domain}”was found to be hosted by ${data.whois.registrar} at IP address ${data.ip}.
This report includes WHOIS registry data, DMARC records, and SPF records.

Summary:
1. The domain name “${data.domain}” is hosted by ${data.whois.registrar}.
2. The IP address associated with this domain is ${data.ip}.
3. The DMARC record status is ${dmarcIsValid}
4. The SPF record status is ${spfRecordFound}

This information was extracted from the WHOIS registry via an API and provided to us.
We have compiled it into a report with a verified hash value.
This report serves as a reference point for your investigation.
The Hash value for this file is: ${data.uuid}

The following pages contain metadata extracted on ${generatedAtInWords}
`
  const subject = 'Analysis of a Domain'
  const ref = `Domain Analysis Report "${data.domain}"`
  const Header = () => (
    <Heading
      fileName="Domain Forensic Report"
      ourReference={data.id}
      reference={ref}
      generatedOn={data.created}
      subject={subject}
    />
  )

  return (
    <ReportPage fromSearchPage={fromSearchPage}>
      <PdfPreview>
        <PdfPage
          pageNumber={1}
          footer={<Footer domain={data.domain} date={generatedAtInWords} uuid={data.uuid} />}
        >
          <Header />
          <S.Body>{body}</S.Body>
        </PdfPage>

        <TablePage
          topSection={<Header />}
          compact
          pageHeaderText="whois info"
          startPageNumber={2}
          errorFallback={<p>Failed to find whois info</p>}
          fields={getWhoisFields(data.whois)}
          footer={<Footer domain={data.domain} date={generatedAtInWords} uuid={data.uuid} />}
        />

        <TablePage
          topSection={<Header />}
          compact
          pageHeaderText="dmarc info"
          startPageNumber={3}
          errorFallback={<p>Failed to find dmarc info</p>}
          fields={getDmarcFields(data.dmarc)}
          footer={<Footer domain={data.domain} date={generatedAtInWords} uuid={data.uuid} />}
        />
      </PdfPreview>
    </ReportPage>
  )
}

const loaderText = `We are preparing your report, this might take us a little while.`
const ReportLoader = () => <Loader text={loaderText} fillScreen />

const GetDomainReport = (props) => {
  const uuid = props?.match?.params?.uuid
  const search = new URLSearchParams(window.location.search)
  const isFromSearchPage = Boolean(search.get('fromSearchPage'))
  
  if (!uuid) {
    return <p>Not found</p>
  }

  return (
    <ErrorBoundary fallback={<p>Something went wrong.</p>}>
      <Get url={`/reports/domain-forensics/${uuid}`} Loader={ReportLoader}>
        {({ data }: { data: DomainReportResponse }) =>
          data ? <Report data={data} fromSearchPage={isFromSearchPage} /> : <p>Not found</p>
        }
      </Get>
    </ErrorBoundary>
  )
}

export default GetDomainReport
