import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Table } from 'react-bootstrap'
import config from '../styles'
import { formatDateTime } from '../../../utils/datetime'

const S = {
    Logo: styled.img`
      margin: auto;
      display: block;
      height: 50px;
      margin-bottom: 40px;
    `,
    Heading: styled.div`
      margin-bottom: 24px;
    `,
    Split: styled.div`
      display: flex;
    `,
    Col: styled.div`
      flex-grow: 1;
    `,
    TBody: styled.tbody`
      * td {
        width: 20%;
      }
    `,
    FirstCol: styled.td`
      color: ${config.colors.primary};
      font-weight: bold;
      padding-bottom: 16px !important;
    `,
    FirstRefCol: styled.td`
      color: ${config.colors.primary};
      font-weight: bold;
      padding-bottom: 6px !important;
    `,
    Header: styled.h1<{ primary?: boolean, fontSize?: number }>`
      max-width: 350px;
      height: 64px;
      word-wrap: anywhere;
      color: ${({ primary }) => (primary ? config.colors.primary : config.colors.gray)};
      font-weight: bold;
      font-size: ${({ fontSize }) => fontSize ?? config.fontSizes.default}px;
    `,
}

interface HeadingProps {
    fileName: string;
    generatedOn: string;
    ourReference: string;
    reference: string;
    subject: string;
}

export default ({ fileName, generatedOn, ourReference, reference, subject }: HeadingProps) => (
        <S.Heading>
            <Helmet>
                <title>
                    {fileName}
                    {' '}
                    -
                    {' '}
                    DIGITPOL
                </title>
            </Helmet>

            {/* TODO add client name here if defined */}

            <Table size="sm" borderless>
                <S.TBody>
                    {/* Empty row to align all other rows */}
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td colSpan={5}>
                            <S.Header primary fontSize={config.fontSizes.large}>{fileName}</S.Header>
                        </td>
                    </tr>
                    <tr>
                        <S.FirstCol>Generated on</S.FirstCol>
                        <td colSpan={4}>{formatDateTime(generatedOn)}</td>
                    </tr>
                    <tr>
                        <S.FirstCol>Our Ref.</S.FirstCol>
                        <td colSpan={4}>
                            {ourReference}
                        </td>
                    </tr>
                    <tr>
                        <S.FirstCol>Subject</S.FirstCol>
                        <td colSpan={4}>{subject}</td>
                    </tr>
                    <tr style={{ borderTop: "1px solid black", borderBottom: "1px solid black" }}>
                        <S.FirstRefCol>Reference</S.FirstRefCol>
                        <td colSpan={4}>{reference}</td>
                    </tr>
                </S.TBody>
            </Table>

        </S.Heading>
    )
